$h1-color: #01274e;
$colorPrime: #026fa6;
$__scrollbar: #4787a756;
$__scrollbar_full: #4787a78e;
$__scrollbar_hover: #026fa6;
$__btcolor: #026fa6;
$__exhibitor: #026fa6;
// $__exhibitor: #e74214;
// $__bg_exhibitor: #eeddd8;
$__bg_exhibitor: #d4d4d4aa;
$active: #e6f7ff;
$active_text: #188fff2d;
$boxCorner: 2px;
$boxCorner1: 8px;
$boxShadow1: 1px 1px 2px 2px #eee;
$boxShadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
$boxShadowHover: 0 1px 2px -2px rgba(0, 0, 0, 0.16),
  0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);

$c_0: #000000;
$c_1: #353540;

$side_float_button_color: #e76969;

// .ant-layout-header,
// .ant-layout-sider {
//     background-color: white;
// }

html {
  font-size: 14px;
}

.__header {
  background-color: white;
  color: black;
  z-index: 10;
  // box-shadow: $boxShadow;
}

.__sider {
  background-color: white;
  border-radius: 0 0; // 24px 0;
  box-shadow: $boxShadow;

  li a:hover {
    background-color: $active;
    // color: $active_text;
  }

  .__collapse_header {
    .ant-collapse-header {
      padding-top: 6px !important;
      padding-bottom: 6px !important;
      border-radius: 4px !important;

      &:hover {
        background-color: $active;
      }
    }

    .ant-collapse-content-box {
      padding-left: 0px !important;
      padding-right: 0px !important;
    }
  }

  .__active_collapse {
    .ant-collapse-header {
      background-color: $active;
    }
  }
}

@media screen and (max-width: 768px) {
  .__sider {
    margin-top: 50px;
  }
}

.__active {
  background-color: $active;
  // color: $active_text;
}

.d-flex {
  display: flex;
}

.w-100 {
  width: 100%;
}

.ant-image-preview-img-wrapper img.ant-image-preview-img {
  display: inline;
}

.col-center {
  display: flex;
  align-content: center;
  align-items: center;
}

.img-circle-square {
  transition: height 0.5s;
  transition: width 0.5s;
  transition: border-radius 1s;
  border: 1px solid #e9e9e9;

  &:hover {
    border-radius: 50%;
    background: #ddd;
    transition: border-radius 0.2s;
  }
}

.__shadow {
  box-shadow: $boxShadow;
}

.layout-appcontent {
  padding: 24px;
  background-color: rgb(241 245 249 / 1) !important;
}

@media only screen and (max-width: 600px) {
  .layout-appcontent {
    padding: 5px;
  }
}

.ant-modal-header {
  background-color: $colorPrime;

  .ant-modal-title {
    color: white;
  }
}

.ant-modal-close-x {
  color: white;
}

.home-content {
  display: flex;
  padding: 20px;
  width: 100%;
}

.programs {
  .program-card {
    width: 100%;
    border-radius: $boxCorner;
    box-shadow: $boxShadow;

    &:hover {
      box-shadow: $boxShadowHover;
    }

    .ant-card-head {
      background-color: $colorPrime;
      color: white;
    }

    .ant-card-head-title {
      padding-bottom: 5px;

      .session-time {
        font-weight: 600;
        font-size: 13px;
      }
    }

    .sub-title {
      font-weight: 500;
      font-size: 16px;
    }

    .ant-card-body {
      padding: 0;
    }
  }

  .ant-card-head-title {
    white-space: normal;
  }

  .program-speaker {
    display: flex;
    width: 100%;
    margin-top: 10px;
    cursor: pointer;

    span {
      display: flex;
    }

    .sp_bd {
      color: black;
      font-size: 15px;
    }

    .sp_des {
      color: gray;
      font-size: 14px;
    }

    .sp_com {
      color: #999;
      font-size: 14px;
    }

    .sp_id {
      color: black;
      font-size: 12px;
    }
  }
}

.__heart_filled {
  color: $colorPrime !important;
}

.delegates-card {
  .sp_bd {
    color: black;
    font-weight: 700;
    // font-size: 20px;
  }

  .sp_des {
    color: #999;
    // font-style: italic;
    // font-size: 18px;
  }

  .sp_com {
    color: #999;
    font-size: 14px;
    // line-height: 1;
  }

  .sp_id {
    color: #999;
    // font-size: 16px;
  }

  &:hover {
    .__avatar {
      transform: scale(1.03);
    }
  }
}

.delegateModal {
  .ant-modal-close-x {
    color: black;
  }

  .content {
    padding-left: 10px;
    padding-right: 10px;
    min-height: 350px;

    .colon {
      margin-top: 15px;
    }

    .session_name {
      color: $colorPrime;
    }

    .ant-divider.ant-divider-horizontal {
      margin: 15px 0;
    }
  }
}

.exihibitorModal {
  .ant-modal-body {
    min-height: 200px;
  }
}

.__bt_more {
  padding-right: 0px;
}

.community-div {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  box-shadow: -4px -4px 10px #8888887a;
}

.__speaker1 {
  $color_1: var(--blue);
  $color_2: white;
  $font-family_1: "Poppins", sans-serif;

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  &:root {
    --back_color: #221f2e;
    --front_color: #2e2b3f;
    --purple: #6240f6;
    --pink: #eb7ac8;
    --blue: #2db8e7;
  }

  * {
    font-family: $font-family_1;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body {
    background: var(--back_color);
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .vcard {
    width: 450px;
    height: 550px;
    background: var(--front_color);
    border-radius: 8px;
    box-shadow: 5px 0px 20px rgba(0, 0, 0, 39%);
  }

  .header {
    height: 40%;
    position: relative;
  }

  .wave_bg {
    border-radius: 8px;
    position: relative;
    height: 100%;
    overflow: hidden;
  }

  .wave-01 {
    position: absolute;
    width: 600px;
    height: 600px;
    background: linear-gradient(to top right, var(--purple), var(--pink));
    border-radius: 50%;
    top: -200%;
    left: -20%;
    animation: rotate 10s linear infinite;
  }

  .wave-02 {
    position: absolute;
    width: 600px;
    height: 600px;
    background: linear-gradient(to top right, var(--purple), var(--pink));
    border-radius: 40%;
    top: -205%;
    left: -22%;
    opacity: 0.5;
    animation: rotate 15s linear infinite;
  }

  .wave-03 {
    position: absolute;
    width: 600px;
    height: 600px;
    background: linear-gradient(to top right, var(--purple), var(--pink));
    border-radius: 45%;
    top: -198%;
    left: -18%;
    opacity: 0.2;
    animation: rotate 15s linear infinite;
  }

  .profile_pic_content {
    width: 130px;
    height: 130px;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 80%;
    text-align: center;

    &::after {
      content: "";
      border-radius: 50%;
      width: 100%;
      height: 100%;
      background: linear-gradient(to right, var(--purple), var(--pink));
      position: absolute;
      top: 8px;
      left: 10px;
      z-index: -1;
      opacity: 0;
      transition: all 0.7s;
    }

    &:hover {
      &::after {
        opacity: 0.2;
      }
    }
  }

  .profile_pic {
    max-width: 100%;
    max-height: 100%;
    border-radius: 50%;
  }

  .card_content {
    position: relative;
    top: 7%;
    text-align: center;
  }

  .name {
    color: $color_1;
    font-weight: 700;
    font-size: 1.4em;
  }

  .info {
    margin-top: 5px;
    color: $color_2;
    padding: 0px 10px;
  }

  .typed_text {
    text-align: left;
  }

  .social {
    color: $color_2;
    padding: 5px;
    margin-top: 65px;

    a {
      text-decoration: none;
      color: $color_2;
    }

    i {
      padding: 5px;
    }
  }

  .button {
    cursor: pointer;
    display: block;
    text-decoration: none;
    width: 50%;
    margin-top: 10px;
    margin-left: 25%;
    background: linear-gradient(to top right, var(--pink), var(--purple));
    padding: 10px 20px;
    border-radius: 20px;
    color: $color_2;
    font-weight: bolder;
    transition: all 0.7s;

    &:hover {
      box-shadow: 0px 8px 20px rgba(0, 0, 0, 39%);
    }
  }
}

.__delegate_card {
  $color_1: #737789;
  $color_3: #fd632f;
  $font-family_1: sans-serif;
  $background-color_1: white;
  cursor: pointer;
  height: 100%;
  // padding-bottom: 30px;
  // min-width: 250px;
  // max-height: 200px;

  .title {
    margin-top: -50px;
    margin-bottom: 90px;

    h3 {
      font-size: 35px;
    }
  }

  .team-one {
    padding-top: 120px;
    padding-bottom: 90px;
    position: relative;
  }

  [class*="bg-shape-"] {
    position: absolute;
  }

  .single {
    border-radius: 5px;
    box-shadow: 0px 10px 60px 0px rgba(46, 61, 98, 0.1);
    border: 1px solid #fffbfb;
    text-align: center;
    position: relative;
    padding-bottom: 30px;
    padding-top: 30px;
    transform: scaleY(1);
    transform-origin: top;
    transition: transform 500ms ease;
    background-color: #b7cdd7;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .image {
      width: 165px;
      height: 165px;
      margin-left: auto;
      margin-right: auto;
      border-radius: 50%;
      overflow: hidden;
      border: 5px solid white;
      transition: all 500ms ease;
    }

    .inner {
      transform-origin: top;
      transition: transform 500ms ease;
      transform: scaleY(1);
      color: black;

      p {
        margin-bottom: 0.5rem;
      }
    }

    &::before {
      content: "";
      border-radius: 5px;
      // background-image: linear-gradient(90deg, hotpink 0%, #34495e 100%);
      background-image: linear-gradient(90deg, #4ac1fc 0%, #34495e 100%);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      transform: scale(1, 0);
      transform-origin: bottom;
      transition: transform 500ms ease;
    }

    &:hover {
      transform: scale(1);
      z-index: 5;

      .inner {
        transform: scale(0.9);
        color: white;
        transform-origin: center;
      }

      .circle {
        &::before {
          opacity: 0.3;
          transform: perspective(200px) scaleX(1) scaleY(0.8);
        }

        &::after {
          opacity: 0.3;
          transform: perspective(200px) scaleX(1) scaleY(0.8);
        }
      }

      &::before {
        transform: scale(1, 1);
        transform-origin: top;
      }
    }
  }

  .social {
    position: absolute;
    bottom: -70px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 11;
    visibility: hidden;
    opacity: 0;
    transition: all 500ms ease;

    >a {
      width: 44px;
      height: 44px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      background-color: $background-color_1;
      opacity: 0.3;
      transition: all 500ms ease;

      i {
        color: $color_1;
        transition: all 500ms ease;
        font-size: 16px;
      }

      &:hover {
        opacity: 1;

        i {
          color: $color_3;
        }
      }
    }

    a+a {
      margin-left: 10px;
    }
  }

  .circle {
    &::before {
      position: absolute;
      top: 0;
      right: 0;
      width: 153px;
      height: 117px;
      content: "";
      background-image: url("/images/team-circle-1-1.png");
      transform: perspective(200px) scaleX(0) scaleY(0);
      transition: transform 500ms ease, opacity 500ms ease;
      opacity: 0;
      transform-origin: right top;
    }

    &::after {
      position: absolute;
      bottom: -16px;
      left: 0;
      width: 140px;
      height: 156px;
      content: "";
      background-image: url("/images/team-circle-1-2.png");
      transform: perspective(150px) scaleX(0) scaleY(0);
      transition: transform 500ms ease, opacity 500ms ease;
      opacity: 0;
      transform-origin: let bottom;
    }
  }
}

.__bt_more {
  font-size: 1rem;
  background-color: $__btcolor;
  border-color: $__btcolor;

  svg {
    vertical-align: bottom;
  }

  &:hover {
    border-color: white;
  }
}

.head-h1 {
  width: 100%;
  font-size: 30px;
  color: $h1-color;
  font-weight: 700;
  padding: 15px;
  padding-left: 0px;
  padding-bottom: 0px;
  align-items: center;

  &.more {
    margin: 0px;
    padding: 15px 0px;
    flex-direction: row-reverse;
  }
}

.__speakerCard {
  display: flex;
  width: 100%;
  margin-top: 30px;
  padding: 0px;

  padding-top: 20px;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
  background-color: $colorPrime;

  .__card {
    border-radius: $boxCorner;
    box-shadow: $boxShadow;
    margin-top: 60px;
    justify-content: center;
    align-content: center;
    display: flex;

    &:hover {
      box-shadow: $boxShadowHover;
    }

    .ant-card-body {
      display: flex;
      align-items: center;
    }

    .sp_bd {
      color: black;
      font-size: 20px;
    }

    .sp_des {
      color: gray;
      font-size: 18px;
      line-height: 1.2;
    }

    .sp_com {
      color: #999;
      font-size: 16px;
    }

    .sp_id {
      color: black;
      font-size: 16px;
    }

    .row-speaker {
      display: flex;
      align-items: center;
      flex-direction: column;
      font-weight: 700;
      padding-top: 70px;
      padding-bottom: 15px;

      .row-speaker-img {
        position: absolute;
        top: -60px;
        border: 3px solid white;
        background-color: rgb(240, 240, 240);
        box-shadow: $boxShadow;
      }

      .ant-row {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
      }
    }
  }
}

.__exhibitorCard {
  height: 100%;

  p {
    // word-break: break-all;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;

    @media only screen and (max-width: 600px) {
      -webkit-line-clamp: 10;
      line-clamp: 10;
    }
  }
}

.__downloadsCard {
  overflow: hidden;

  .__title {
    color: black;
    font-weight: 700;
  }

  .__description {
    color: #999;
  }

  .__avatar {
    background-color: #ccc;
    font-size: 55px;
    align-items: center;
    text-align: center;
    color: white;
    width: 110px;
    min-height: 110px;
  }

  &:hover {
    .__avatar {
      transform: scale(1.05);
    }
  }
}

.__scrollbar,
html {
  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-track {
    background: rgba(204, 204, 204, 0.75);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: $__scrollbar_full;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: $__scrollbar_hover;
  }
}

.__scrollbar {
  ::-webkit-scrollbar-track {
    background: rgba(204, 204, 204, 0.226);
  }

  ::-webkit-scrollbar-thumb {
    background: $__scrollbar;
  }
}

.__bt_submit {
  background-color: $__btcolor;
  min-width: 90px;
  color: white !important;
  border-color: $__btcolor !important;
}

.__bt_primary {
  background-color: $__btcolor;
  min-width: 90px;
  color: white !important;
  border-color: $__btcolor !important;

  &.__bt_reverse {
    background-color: white !important;
    color: $__btcolor !important;
    border-color: $__btcolor !important;

    &:hover {
      background-color: $__btcolor !important;
      color: white !important;

      a {
        color: white !important;
      }
    }
  }

  a {
    color: $colorPrime !important;
  }
}

.__bt_cart {
  color: white !important;
  background-color: $__exhibitor !important;

  &:hover,
  &:focus,
  &:active {
    color: gold !important;
    background-color: $__exhibitor !important;
  }
}

.__cart_products {
  img {
    min-height: 150px;
    object-fit: contain;
  }

  .__info {
    background-color: $__bg_exhibitor;
    padding: 10px;
    color: black;
  }

  .__title {
    color: $__exhibitor;
  }

  .__price span {
    color: $__exhibitor;
  }
}

.__support_card {
  .__title {
    color: $__exhibitor;
  }

  .__info {
    border-color: $__exhibitor;
  }
}

.__form_exhibitor {
  .ant-form-item-label>label {
    font-weight: bold;
    color: black;
    height: auto;
    white-space: normal;
    font-size: 110%;

    &:not(.ant-form-item-required)::before {
      content: " ";
      margin-right: 4px;
      width: 8px;
    }
  }

  .ant-form-item-control {
    justify-content: center;
  }

  .ant-form-item-label-left::after {
    content: ":";
    position: absolute;
    right: 10px;
    font-size: larger;
    font-weight: bold;
    // top: 25%;
  }
}

.__dots {
  button {
    background-color: #868686 !important;
    width: 8px !important;
    height: 8px !important;
    border-radius: 50% !important;
  }
}

.__bt_addnew {
  background-color: #28a745 !important;
  color: white !important;
  border-radius: 5px !important;
  border: 0 !important;

  &:hover,
  &:focus,
  &:active {
    background-color: #065f06 !important;
    color: white !important;
    border: 0 !important;
  }
}

.__bt_edit {
  background-color: $colorPrime !important;
  color: white !important;
  border-radius: 5px !important;
  border: 0 !important;

  &:hover,
  &:focus,
  &:active {
    background-color: $h1-color !important;
    color: white !important;
    border: 0 !important;
  }
}

.__bt_sendmail {
  background-color: #dc3545 !important;
  color: white !important;
  border-radius: 5px !important;
  border: 0 !important;

  &:hover,
  &:focus,
  &:active {
    background-color: #85131f !important;
    color: white !important;
    border: 0 !important;
  }
}

.__bt_downloadBadge {
  background-color: #17a2b8 !important;
  color: white !important;
  border-radius: 5px !important;
  border: 0 !important;

  &:hover,
  &:focus,
  &:active {
    background-color: #0a7686 !important;
    color: white !important;
    border: 0 !important;
  }
}

.__bt_transparent {
  background-color: transparent !important;
  color: black !important;
  border-radius: 5px !important;

  // border: 0;
  &:hover,
  &:focus,
  &:active {
    background-color: #0a7686 !important;
    color: white !important;
    // border: 0;
    border-color: currentColor !important;
  }
}

.anticon {
  vertical-align: 1px;
}

.__card_header {
  background-color: $__scrollbar_hover;
  color: white;
}

.ant-spin-nested-loading {
  width: 100%;
}

.__modal_page {
  .ant-modal-title {
    padding-right: 20px;
  }

  .ant-modal-body {
    margin: 10px;

    ul {
      list-style-type: disc;
      list-style: disc;
    }
  }
}

.__termsModal {
  .ant-modal-title {
    color: $colorPrime;
  }

  .ant-btn-primary {
    background-color: $__btcolor;
    color: white !important;
    border-color: $__btcolor !important;
  }
}

.__pdt_index {
  .ant-checkbox-group-item {
    min-width: 200px;
    margin-bottom: 5px;
  }
}

.__login_bottom_carousel .ant-carousel {
  height: 200px;
  width: 300px;
  display: flex;
  align-items: center;

  .slick-slider {
    width: 100%;
    height: 100%;

    img {
      object-fit: contain;
      height: 200px;
    }
  }
}

.__aimatches p {
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  color: #1c1c1c;
  text-align: justify;
}

.testimonials {
  // overflow-x: auto;
  overflow-x: hidden;
  padding: 10px;
  max-height: 500px;

  .__booking {
    max-width: 500px;
    min-width: 400px;
    width: auto;
  }
}

.testimonials {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  min-height: 375px;
  perspective: 500px;

  .item {
    // max-width: 46rem;
    // border: 2px solid #e9e9e9;
    position: absolute;
    // width: 50vw;
    text-align: center;
    transition: transform 0.4s;
    transform-style: preserve-3d;
    user-select: none;

    filter: blur(1px);
    display: flex;
    align-items: center;
    justify-content: center;
    --index: 1;
    transition: transform linear 800ms;
    transform: none;

    &.previous,
    &.next {
      // height: 250px;
      // width: 200px;
      cursor: pointer;

      overflow: hidden;

      .__aimatches p {
        -webkit-line-clamp: 4;
      }

      // .__aimatches {
      //   height: 100%;

      // }
    }

    &.previous {
      transform: translateX(-300px) translateY(-15px) translateZ(0px);
      z-index: var(--index);
    }

    &.next {
      transform: translateX(300px) translateY(-15px) translateZ(0px);
      z-index: calc(100 - var(--index));
    }

    &.active {
      filter: blur(0px);
      transform: translateX(0px) translateY(0px) translateZ(0px);
      box-shadow: 1px 2px 20px rgba(0, 0, 0, 0.3);
      z-index: 200;
      width: 80%;
      position: relative;
      border: 0;
      overflow: hidden;
      margin-bottom: 18px;
      border-radius: 12px;
    }
  }
}

.__legend {
  width: 8px;
  height: 8px;
  border: 1px solid black;
  padding: 7px;
}

.__tag {
  min-width: 60px;
  text-align: center;
  transition: background-color 0.5s;
  cursor: pointer;

  &:hover {
    background-color: $__btcolor;
    color: white;
    border-color: white;
  }

  &.__active {
    background-color: $active;
    border-color: #80bdff;
    color: black;

    // &:hover {
    //   color: black;
    // }
  }
}

.__cards_title {
  background-color: $colorPrime;
  color: white;
}

.__pending {
  background-color: #ef9797;
  color: white;

  &.__opacity50 {
    background-color: #ef979745;
    color: black;
  }
}

.__booked {
  color: white;
  background-color: #ff0000;

  &.__opacity50 {
    background-color: #ff00009d;
    color: black;
  }
}

.__confirmed {
  color: white;
  background-color: #59a16a;

  &.__opacity50 {
    background-color: #59a16a86;
    color: black;
  }
}

.__rejected {
  color: white;
  background-color: #929193;

  &.__opacity50 {
    background-color: #c1c1c186;
    color: black;
  }
}

.__slots {
  .__pending {
    pointer-events: none;
  }

  .__booked {
    pointer-events: none;
  }

  .__confirmed {
    pointer-events: none;
  }

  .__rejected {
    // pointer-events: none;
  }
}

.__b2bhistory {
  .ant-table-cell {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .ant-table-tbody>tr.ant-table-row:hover>td,
  .ant-table-tbody>tr>td.ant-table-cell-row-hover {
    background-color: inherit;
  }
}

.__black {
  color: #000000 !important;
}

.__img {
  img {
    object-fit: contain !important;
  }
}

.__filters {
  a {
    color: black;
  }
}

.__table {
  tr {
    // cursor: pointer;
  }

  td {
    // min-width: 145px;
    padding: 5px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 150px;
    max-height: 250px;
  }
}

.__red {
  color: #bc2026;
}

.__responsive {
  .ql-toolbar {
    display: flex;
    flex-wrap: wrap;
  }

  .ql-container.ql-snow {
    min-height: 160px;
  }

  .ql-editor.ql-blank {
    min-height: 160px;
  }
}

.__card {
  width: 100%;
  border: 1px solid #ccc;
  background-color: transparent;
  word-break: break-all;
  height: 100%;
}

.__bordered {
  border: 1px solid #ccc;
}

.__transcard {
  width: 100%;
  background-color: transparent;
  word-break: break-all;
  height: 100%;
  border: none;
}

.__button {
  border-radius: 20px 0 0 20px;
  position: absolute;
  z-index: 999;
  right: -24px;
  // left: 0;
  top: -20px;
  // top: 100px;
  min-width: 150px;
  text-align: left;
}

.ant-modal-confirm-btns {
  .ant-btn-primary {
    background-color: $__btcolor;
    color: white !important;
    border-color: $__btcolor !important;
  }
}

.__testNav {
  ul {
    >li {
      >ul {
        display: none;

        &.expanded {
          display: block;
        }

        >li {
          display: block;
        }
      }
    }
  }

  li {
    position: relative;
    // i {
    //   position: absolute;
    //   top: 50%;
    //   right: 10px;
    //   transform: translateY(-50%);
    //   font-size: 12px;
    //   color: #999;
    //   transition: transform 0.2s ease;
    // }
    // &.expanded i {
    //   transform: translateY(-50%) rotate(180deg);
    // }
  }
}

.loginNote {
  padding-top: 30px;
}

.loginNote a {
  color: red;
}

.__exhibitor_card {
  background-color: $__bg_exhibitor !important;

  .ant-card-body {
    padding: 0;
  }
}

.__sponsor_modal,
.__confirm_modal,
.__modal_prime {
  .ant-modal-content {
    padding: 0 !important;
  }

  .ant-modal-footer {
    padding: 16px !important;
  }

  .ant-modal-title {
    color: white !important;
    padding: 16px !important;
  }

  .__open_web {
    background-color: $__btcolor;

    &:hover {
      background-color: #0782bf !important;
      color: #000000;
    }
  }
}

.__sponsor_modal,
.__modal_prime {
  .ant-modal-header {
    border-top: 8px !important;
    background-color: $colorPrime !important;
  }
}

.__confirm_modal {
  .ant-modal-body {
    padding: 0px 16px;
  }

  .ant-modal-footer {
    margin-top: 0;
    padding-top: 0;
  }
}

.ant-tag {
  display: flex;
  align-items: center;
}

.__team_slide {
  .slick-arrow {
    color: $colorPrime;
  }
}

.__primary_color {
  color: $colorPrime !important;
}

.__primary_color_bg {
  background-color: $colorPrime !important;
  color: white !important;

  &:hover {
    background-color: lighten($colorPrime, 10%) !important;
    color: white;
  }
}

#meetingSDKElement {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

// #meetingSDKElement0 {
//   display: block !important;

//   .MuiPaper-elevation .MuiPaper-elevation:first-of-type {
//     width: auto !important;
//   }
// }

.__container_speakers {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: flex-start;

  .__item {
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    flex: 1 1 250px;
    max-width: 250px;
    box-sizing: border-box;
  }
}

.__tabs_common {
  .ant-tabs-tab-active {
    background-color: $colorPrime !important;
  }

  &.__full_w_tab {
    .ant-tabs-nav-list {
      width: 99.99%; //antd tab flickering fix
    }

    .ant-tabs-tab {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0px !important;
      border: 1px solid white !important;
    }
  }

  &.__tabpane_bg {
    .ant-tabs-tabpane {
      // background-color: #cfcfcf;
      padding: 8px 16px;
      border: 1px solid white !important;
    }

    .ant-tabs-nav {
      margin-bottom: 0;
    }

    .ant-tabs-content-holder {
      // margin: 0px;
      // border: 0px solid #e9e9e9;
    }
  }
}

.ant-select-selection-item .__additional {
  display: none !important;
}

.__delegate_avatar {
  display: flex;
  border: 1px solid rgb(236, 236, 236);
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  padding: 0.3rem;

  img {
    object-fit: contain;
    border-radius: 6px;
    width: fit-content;
  }
}

// .__header_style_scroll{
//   background-color: transparent;
//   backdrop-filter: blur(5px);
// }

// .__header_style_default {
//   background-color: white;
//   transition: background-color 0.3s, backdrop-filter 0.3s;
// }

// .layout-appcontent{
//   height: 90vh !important;
//   overflow: auto !important;
// }

.__feedback_form {
  .ant-form-item-label {
    font-weight: bold;
  }

  .ant-form-item {
    margin: 0;
  }

  .__feedback_label {
    @media screen and (min-width: 1024px) {
      .ant-form-item-label>label {
        font-size: 1.125rem;
      }
    }
  }
}

.__programs .antd-switch {
  background-color: gray;
}

@media screen and (min-width: 992px) {
  .__eventDetails_carousel {
    max-width: 43vw;
  }
}

.__eventDetails_carousel {
  .slick-dots-bottom {
    bottom: 24px !important;
  }

  .slick-slide>div>div {
    display: flex !important;
    justify-content: center !important;
  }

  .slick-dots li {
    width: 8px !important;

    &.slick-active {
      width: 8px !important;
    }

    button {
      background-color: $colorPrime !important;
      height: 8px !important;
      width: 8px !important;
      border-radius: 100% !important;
      overflow: hidden !important;
    }
  }
}

.__expandable_button_group {
  pointer-events: none;

  .ant-badge {
    pointer-events: all;
  }

  .button {
    display: block;
    margin: 8px 0px;
    min-width: 32px;
  }

  .button>* {
    align-items: center;
    background-color: $side_float_button_color;
    border-radius: 60px 0px 0px 60px;
    color: white;
    display: inline-flex;
    justify-content: flex-start;
    height: 32px;
    overflow: hidden;
    width: auto;
    text-decoration: none;
    max-width: 32px;
    transition: max-width 0.7s ease-in-out;
    padding-right: 2px;
    cursor: pointer;
  }

  .button:hover>* {
    max-width: 300px;
  }

  .icon :hover,
  .text :hover {
    .button>* {
      max-width: 300px !important;
    }
  }

  .icon {
    font-family: "Font Awesome 5 Free";
    font-size: 16px;
    margin-right: 0;
    margin-left: 15px;
    padding-right: 4px;
    display: flex;
    align-items: center;
  }

  .text {
    white-space: nowrap;
    padding: 0px 8px;
    pointer-events: auto;
    cursor: pointer;
  }
}

.program-card {
  .ant-card-body {
    min-height: 150px !important; //to make the flaot buttons visible when empty content
    position: relative;
  }

  .__program_row {
    min-height: 150px;
  }

  .__subprgm_row {
    position: sticky;
    min-height: 150px;
  }
}

.__award_card_img {
  transition: scale 0.4s ease-in-out;

  &:hover {
    scale: 1.05;
  }
}

.__login_banner {
  justify-content: space-around;
  background: var(--bgcontent);

  .__divider {
    background: var(--bg);
  }

  .__banner_img {
    padding: 10px;

    img {
      @media screen and (min-width: 900px) and (min-height: 720px) {
        max-height: calc(100vh - 240px);
      }
    }
  }
}

.ql-picker.ql-font {
  .ql-active {
    &:before {
      content: attr(data-value) !important;
    }
  }
}

// .ql-picker.ql-font .ql-picker-label[data-value="times-new-roman"]::before,
// .ql-picker.ql-font .ql-picker-item[data-value="times-new-roman"]::before {
//   font-family: "Times New Roman";
//   content: "Times New Ro" !important;
// }

// .ql-container {
//   font-family: "Times New Roman" !important;
// }

.ql-picker.ql-font .ql-picker-label[data-value="TimesNewRoman"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="TimesNewRoman"]::before {
  font-family: "Times New Roman";
  content: "Times New Roman" !important;
}

.ql-font-TimesNewRoman {
  font-family: "Times New Roman";
}

.react-pdf__Page {
  background-color: transparent !important;
}

.react-pdf__Page__textContent {
  align-self: center;
}

.ql-snow {
  .ql-picker {
    &.ql-size {

      .ql-picker-label,
      .ql-picker-item {
        &::before {
          content: attr(data-value) !important;
        }
      }
    }
  }
}

.ql-editor {
  padding-bottom: 32px !important;
  // font-family: "Times New Roman";
}

.ql-picker-label {
  padding-right: 5px;
  overflow: hidden;
}

// Gallery Styles

$color: #ed2437;

.__main_bg {
  background: $color;
  background: linear-gradient(149deg,
      rgba(233, 111, 45, 1) 0%,
      rgba(244, 142, 23, 1) 43%,
      rgba(255, 175, 0, 1) 100%) !important;
}

// h1,
// h2,
// h3,
// h4,
// h5 {
//   // font-family: '"Great Vibes", Roboto' !important;
// }

.__titleColor {
  color: $color;
}

a:not(.wd_social_icons a):hover {
  color: $color !important;
}

// @media only screen and (max-width: 700px) {
//   .__header {
//     padding-left: 16px !important;
//     padding-right: 16px !important;
//   }
// }

// .__header li.ant-menu-item {
//   color: $color;
// }

.__secondary_bg {
  background: rgb(124, 62, 224) !important;
  background: linear-gradient(37deg,
      rgba(124, 62, 224, 1) 0%,
      rgba(81, 203, 197, 1) 100%) !important;
}

// html {
//   overflow-x: hidden;
//   width: 100% !important;
//   // margin-right: calc(-1 * (100vw - 100%));
// }

// body {
//   width: 100% !important;
// }

//   .__album_cover:before {
//     content: "";
//     height: 15px;
//     width: 70px;
//     background-color: rgba(255, 255, 255, .3);
//     position: absolute;
//     top: 5px;
//     left: 5px;
//     z-index: 100;
//     transform: translateX(-30%) translateY(15%) rotate(-45deg);
//     box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.4);
// }

// .__album_cover {
//   position: relative;

//   .gallery {
//     --size: min(60vmin, 400px);
//     position: relative;
//     box-shadow:
//       0 0 10px #0002,
//       0 20px 40px -20px #0004;
//     width: var(--size);
//     height: var(--size);
//     background: #fff;
//     border: 6px solid #fff;
//     display: grid;
//     grid-template-rows: 50% 50%;
//     grid-template-columns: 1fr 1fr;
//     overflow: hidden;
//     // gap: 6px;
//     margin: auto;
//     transition: all 3s ease-in-out;
//   }

//   .gallery:hover {
//     .__album_title {
//       opacity: 1;
//     }
//   }

//   .__album_title {
//     background-color: rgba(54, 52, 52, 0.4);
//     position: absolute;
//     top: 0;
//     color: white;
//     width: 100%;
//     height: 100%;
//     font-size: 25px;
//     padding: 15px 0;
//     text-align: center;
//     opacity: 0;
//     transition: 0.8s;
//   }

//   .gallery img {
//     width: 100%;
//     height: 100%;
//     object-fit: cover;
//   }

//   .gallery img:nth-child(1) {
//     grid-column: 1;
//     grid-row: 1;
//     justify-self: end;
//     width: 0;
//     animation-fill-mode: forwards;
//   }

//   .gallery img:nth-child(2) {
//     grid-column: 2;
//     grid-row: 1;
//     justify-self: start;
//     height: 0;
//   }

//   .gallery img:nth-child(3) {
//     grid-row: 2;
//     grid-column: 1 / 3;
//     align-self: end;
//     object-position: 0 0;
//   }

//   .gallery img:nth-child(4) {
//     grid-column: 1 / 3;
//     grid-row: 1;
//     width: 0;
//     justify-self: center;
//     align-self: start;
//   }

//   .gallery img:nth-child(5) {
//     grid-column: 1;
//     grid-row: 2;
//     width: 0;
//     justify-self: start;
//     align-self: end;
//   }

//   .gallery img:nth-child(6) {
//     grid-column: 2;
//     grid-row: 2;
//     width: 0;
//     justify-self: end;
//     align-self: end;
//   }

//   .gallery img:nth-child(7) {
//     grid-column: 1/3;
//     grid-row: 1/3;
//     width: 0;
//     justify-self: end;
//     align-self: end;
//     object-position: 0 0;
//   }
// }

.galleryStyle {
  --size: min(80vmin, 400px);
  position: relative;
  box-shadow: 0 0 10px #0002, 0 20px 40px -20px #0004;
  width: var(--size);
  height: var(--size);
  background: #fff;
  border: 6px solid #fff;
  display: grid;
  grid-template-rows: 50% 50%;
  grid-template-columns: 1fr 1fr;
  overflow: hidden;
  margin: auto;
  // transition: all 3s ease-in-out;
  gap: 6px;
  border-radius: 16px;
  transition: all ease-in-out 0.3s;
}

.galleryStyle:hover {
  transform: scale(1.02);
}

.galleryStyle img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@keyframes moveHorizontal {
  to {
    object-position: 100% 0;
  }
}

@keyframes moveVertical {
  to {
    object-position: 0 100%;
  }
}

@keyframes shrinkVertical {
  to {
    height: 0;
  }
}

@keyframes shrinkHorizontal {
  to {
    width: 0;
  }
}

@keyframes growHorizontal {
  to {
    width: 100%;
  }
}

@keyframes growHorizontal2 {
  to {
    width: 70%;
  }
}

@keyframes growVertical {
  to {
    height: 100%;
  }
}

@keyframes growAll {
  to {
    width: 100%;
    height: 100%;
  }
}

@keyframes transitionSmooth {
  50% {
    opacity: 0.8;
  }

  100% {
    opacity: 1;
  }
}

.transitionSmoothly {
  animation: transitionSmooth 1s;
}

.gallery img:nth-child(1) {
  animation: moveHorizontal 2.5s 0.5s 1, shrinkHorizontal 2s 3s ease-in 1;
}

.galleryStyle img:nth-child(1) {
  grid-column: 1;
  grid-row: 1;
  justify-self: end;
}

.gallery img:nth-child(2) {
  animation: shrinkHorizontal 2s 5s 1;
  /* Adjusted delay */
  animation-fill-mode: forwards;
}

.galleryStyle img:nth-child(2) {
  grid-column: 2;
  grid-row: 1;
  justify-self: end;
}

.gallery img:nth-child(3) {
  animation: moveVertical 5s 1s 1, shrinkVertical 3s 6s 1;
  /* Adjusted delay */
  animation-fill-mode: forwards;
}

.galleryStyle img:nth-child(3) {
  grid-row: 2;
  grid-column: 1 / 3;
  align-self: end;
  object-position: 0 0;
}

.gallery img:nth-child(4) {
  animation: growHorizontal 2.25s 5s 1, moveHorizontal 4s 7.25s 1,
    shrinkVertical 2s 11.25s 1;
  /* Adjusted delays */
  animation-fill-mode: forwards;
}

.galleryStyle img:nth-child(4) {
  grid-column: 1 / 3;
  grid-row: 1;
  width: 0;
  justify-self: center;
  align-self: start;
}

.gallery img:nth-child(5) {
  animation: growHorizontal 2.5s 3.5s 1, moveVertical 4s 6s 1,
    shrinkHorizontal 2s 10s 1;
  /* Adjusted delays */
  animation-fill-mode: forwards;
}

.galleryStyle img:nth-child(5) {
  grid-column: 1;
  grid-row: 2;
  width: 0;
  justify-self: start;
  align-self: end;
}

.gallery img:nth-child(6) {
  animation: growHorizontal 2s 4s 1, shrinkHorizontal 2s 10s 1;
  /* Adjusted delays */
  animation-fill-mode: forwards;
}

.galleryStyle img:nth-child(6) {
  grid-column: 2;
  grid-row: 2;
  width: 0;
  justify-self: end;
  align-self: end;
}

.gallery img:nth-child(7) {
  animation: growHorizontal 2s 13s 1, moveHorizontal 2s 14.5s 1;
  /* Adjusted delays */
  animation-fill-mode: forwards;
}

.galleryStyle img:nth-child(7) {
  grid-column: 1/3;
  grid-row: 1/3;
  width: 0;
  justify-self: end;
  align-self: end;
  object-position: 0 0;
}


.__album_title {
  background-color: rgba(54, 52, 52, 0.8);
  position: absolute;
  top: 0;
  color: white;
  width: 100%;
  height: 100%;
  font-size: 25px;
  padding: 15px 0;
  text-align: center;
  opacity: 0;
  transition: 0.8s;
}

.galleryStyle:hover {
  .__album_title {
    opacity: 1;
  }
}

// *********************** START footer social **************
$color_1: #fff;
$color_2: #ffffff;

ul.wd_social_icons {
  float: left;
  width: 100%;
  padding: 0px;
  margin: 0px 0px;

  li {
    list-style: none;
    padding: 0px 10px;
    display: inline-block;

    a {
      font-size: 20px;
      border: 2px solid #fff;
      border-radius: 100%;
      width: 50px;
      height: 50px;
      float: left;
      line-height: 45px;
      color: $color_1;
      -webkit-transition: 0.3s ease;
      transition: 0.3s ease;

      &:hover {
        transform: rotate(45deg);
        border-radius: 0px;

        i {
          transform: rotate(-45deg);
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  ul.wd_social_icons {
    li {
      a {
        width: 40px;
        height: 40px;
        line-height: 40px;
      }
    }
  }
}

// p {
//   color: $color_2;
// }

// *********************** END footer social **************

// *********************** START masnory **************

// .my-masonry-grid {
//   display: -webkit-box;
//   display: -ms-flexbox;
//   display: flex;
//   margin-left: -30px;
//   width: auto;

// }

// .my-masonry-grid_column {
//   padding-left: 30px;
//   background-clip: padding-box;
// }

// .my-masonry-grid_column > div {
//   background: grey;
//   margin-bottom: 30px;
// }

.my-masonry-grid {
  // display: -webkit-box;
  // display: -ms-flexbox;
  // margin-left: -30px;
  display: flex;
  width: auto;

  // column-gap: 10px;
  // row-gap: 10px;
  // column-count: 4;
}

.my-masonry-grid_column {
  // padding-left: 30px;
  background-clip: padding-box;
  // border-radius: 10px;
  // column-gap: 10px;
  // row-gap: 10px;
  display: grid;
  gap: 5px;
}

.my-masonry-grid_column>div {
  // background: grey;
  // margin-bottom: 30px;
}

// *********************** END masnory **************


.__custom_preview_grp {

  // .ant-image-preview-img{
  //   opacity: 0;
  // }

  .ant-image-preview-switch-right,
  .ant-image-preview-switch-left {
    border: 1px solid #a7a7a79e;
    -webkit-box-shadow: 10px 17px 34px -12px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 10px 17px 34px -12px rgba(0, 0, 0, 0.75);
    box-shadow: 10px 17px 34px -12px rgba(0, 0, 0, 0.75);
  }


  .ant-image-preview-footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 16px;
  }

  .ant-image-preview-progress {
    margin-top: auto;
    margin-bottom: auto;
  }

  .ant-image-preview-img-wrapper {
    margin: 8px;
  }

  .ant-image-preview-img {
    transition: all 1s ease-in-out;
    opacity: 1;
  }
}


@keyframes customAnimate {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

// .__h1_gallery {
//   // font-size: 3rem !important; 
//   // font-family: Arial, Helvetica, sans-serif;
//   // font-style: italic;
//   margin: 16px;
//   font-weight: 700; 
//   text-align: center; 
//   color: #2c3e50; 
//   margin-bottom: 10px; 
//   letter-spacing: 2px; 
//   text-transform: uppercase;
//   background: linear-gradient(90deg, #fd8383, #cf0202);
//   -webkit-background-clip: text;
//   color: transparent; 
// }
.__login_bg::before {
  content: "";
  background-image:
    url('/login_bg.jpg');
  position: fixed;
  background-attachment: scroll;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  filter: blur(4px);
  margin: -10px;
}

.__modal_share,
.__modal_prfile_update,
.__gallery_profile {
  .ant-modal-title {
    color: #000000;
  }

  .ant-btn-primary {
    background-color: $colorPrime;
  }
}

.__modal_common {
  .ant-modal-content {
    padding: 0 !important;
  }

  .ant-modal-title {
    padding: 10px;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    background-color: $colorPrime;
    color: white !important;
  }

  .ant-modal-body {
    padding: 10px !important;
  }
}

@media screen and (max-width:768px) {
  .__App_Links {
    width: 100%;
  }
}

@media screen and (min-width:769px) {
  .__App_Links {
    width: calc(100vw - 210px);
}

}